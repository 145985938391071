import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import "./_referring-pages.scss";
import TEXT from "../../config/text";

import useTranslation from "../../hooks/useTranslation";

export default function TermsOfService() {
  const { appInfo } = useSelector((store) => store.globalState);
  const translation = useTranslation();
  const selectedLanguage = useSelector((store) => store.globalState.selectedLanguage);

  const handleText = (text) => {
    if (translation?.pages?.terms_of_use?.[text]) {
      return translation?.pages?.terms_of_use?.[text];
    }
    return TEXT?.pages?.terms_of_use?.[text];
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
  return (
    <div className="referring-pages">
      <div
        style={{
          marginTop: "60px",
        }}
      >
        {/* <h1>Terms of use</h1>
        <p>
          We invite you to read these terms of HIBERDEALS company on{" "}
          {appInfo.app_name} site. The validation of your online transaction
          form is considered as an irrevocable acceptance of these terms.
        </p>
        <p> 1. Preamble </p>
        <p>
          The application {appInfo.app_name} Player is delivered without any
          content. In addition, no content is provided from the application even
          after activating it on our website. The information displayed at the
          beginning includes the MAC address as well as the website so that
          clients can activate the application once for life. The MAC address
          and the URL are essential for the proper functioning of the
          application. {appInfo.app_name} Player does not provide any content or
          subscription. The content and subscriptions provided under the{" "}
          {appInfo.app_name} Player app name are frauds. {appInfo.app_name}{" "}
          Player disclaims any responsibility for the use of fraudulent content.
          The application {appInfo.app_name} Player or it is entitled can not be
          held as responsible for the quality or interruptions of the content
          used on {appInfo.app_name}
          Player, {appInfo.app_name} Player only provides a media player. The
          application {appInfo.app_name}
          Player does not provide any advertising for content providers or
          subscriptions and reserves the right to sue anyone who uses our name
          or logo for advertising purposes.¸
        </p> */}
        {
          selectedLanguage.iso_code == "hy" ? <>
            <h1>Օգտագործելու կանոններ</h1>
            <div>I Player հավելվածը առանց որևէ բովանդակության է: Բովանդակություն չի տրամադրվում նույնիսկ մեր կայքում՝ այն ակտիվացնելուց հետո:

              Սկզբում ցուցադրվում է ՄԱԿ հասցեի և վեբ կայքի ինֆորմացիա, որպեսզի հաճախորդները կարողանան ակտիվացնել հավելվածը ցմահ մեկ անգամ:

              I Player Մեդիա Փլեյեր հավելվածն ունի 5-օրյա անվճար փորձաշրջան՝ հավելվածի հնարավորությունները ստուգելու համար:

              MAC հասցեն և URL-ը կարևոր են հավելվածի պատշաճ գործունեության համար:

              I Player-ը չի տրամադրում որևէ կոնտենտ կամ բաժանորդագրություն:

              I Player-ի անվան տակ տրամադրված բովանդակությունը և բաժանորդագրությունները խարդախություններ են և պատասխանատվություն չի կրում այլ կոնտենտով օգտագործման համար:

              I Player հավելվածը չի կարող պատասխանատվություն կրել I Player-ում օգտագործվող բովանդակության որակի կամ ընդհատումների համար։

              I Player-ը տրամադրում է միայն մեդիա փլեյեր:

              I Player հավելվածը գովազդ չի տրմամադրում կոնտենտ պրովայդերներին կամ բաժանորդագրություն առաջարկողներին և իրեն իրավունք է վերապահում դատի տալ բոլորին, ովքեր օգտագործում են մեր անունը կամ պատկերանշանը գովազդային նպատակներով:

              Վերոնշյալ պայմաններն ընդունելուց հետո ոչ մի դեպքում գումարի վերադարձ չի տրվի:

              Նշում. Եթե դուք օգտագործում եք գողացված քարտ, ձեր ՄԱԿ-ը կարգելափակվի, և դրա IP հասցեն ևս:

              I Player-ը պատկանում և շահագործվում է I Player-ի կողմից, որն իրեն իրավունք է վերապահում սահմանափակել մուտքը դեպի հավելված, փոխել դրա գինը և փոփոխել օգտագործման պայմանները ցանկացած ժամանակ՝ առանց իր վերջնական օգտատիրոջ համաձայնության:



              Օգտագործողի պարտականությունն է հետևել թարմացումներին։</div>
          </> : <>
            <h1>{handleText("page_name")} </h1>
            <div dangerouslySetInnerHTML={{ __html: handleText("content") }} />
          </>
        }
      </div>
    </div>
  );
}
